import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ColumnContainer, RowContainer } from "../../styles/containers/styles";
import { PageHead } from "../../styles/headings";
// import Layout from "../../common/layout";y
import { Grouping, FaqList, Group, FaqItem, Question, Answer } from "./styels";
import withLayout from "../layout";
const Faq = () => {
   const { t } = useTranslation();
   //to update the page title
   useEffect(() => {
    document.title = `Ticket Portal - innopark24`;
  }, [t]);
  //dispatch is using to update the state in store
  const dispatch = useDispatch();
  
  //theme color is getting from redux
  const themeColors = useSelector((state) => state.themeColors);

  //selectedGroup varibale to store the selected group
  const selectedGroup = useSelector((state) => state.selectedFAQGroup);

  //this fetching state value from redux store this will update when we use dispatch
  const faqGroup = useSelector((state) => state.faqGroup);
  const faqs = useSelector((state) => state.faq);
  useEffect(() => {
      
  const fetchFaqGroup = () => {
    return (dispatch) => {
      const faqGroups = [
        {
          id: 1,
          name: "general",
        },
        {
          id: 2,
          name: "system",
        },
        {
          id: 3,
          name: "proofPictures",
        },
        {
          id: 4,
          name: "driverData",
        },
        {
          id: 5,
          name: "serviceProvider",
        },
        {
          id: 6,
          name: "carBreakdown",
        },
        {
          id: 7,
          name: "paymentRequest",
        },
        {
          id: 8,
          name: "dataProtection",
        },
        {
          id: 9,
          name: "eVechileCharging",
        }
      ];
  
      dispatch(
        {
          type: "FETCH_FAQ_GROUP_SUCCESS",
          payload: faqGroups,
        },
        {
          type: "SELECT_FAQ_GROUP",
          payload: faqGroups[0]?.id,
        }
      );
    };
  };
    // Dispatch action to fetch FAQ group if it doesn't exist
    if (!faqGroup.data) {
      dispatch(fetchFaqGroup());
      // Dispatch action to fetch FAQs for the first group in the list
    }
    // Re-run the effect when dispatch or faqGroup changes
  }, [t, dispatch, faqGroup]);

  const fetchFaq = (id) => {
    return (dispatch) => {
      const faqQuestions = [
        {
          id: 1,
          question: "generalQuestion1",
          answer: "generalAnswer1",
        },
        {
          id: 1,
          question: "generalQuestion2",
          answer: "generalAnswer2",
        },
        {
          id: 1,
          question: "generalQuestion3",
          answer: "generalAnswer3",
        },
        {
          id: 2,
          question: "systemQuestion1",
          answer: "systemAnswer1",
        },
        {
          id: 3,
          question: "proofPicturesQuestion1",
          answer: "proofPicturesAnswer1",
        },
        {
          id: 4,
          question: "driverDataQuestion1",
          answer: "driverDataAnswer1",
        },
        {
          id: 4,
          question: "driverDataQuestion2",
          answer: "driverDataAnswer2",
        },
        {
          id: 5,
          question: "serviceProviderQuestion1",
          answer: "serviceProviderAnswer1",
        },
        {
          id: 6,
          question: "carBreakdownQuestion1",
          answer: "carBreakdownAnswer1",
        },
        {
          id: 7,
          question: "paymentRequestQuestion1",
          answer: "paymentRequestAnswer1",
        },
        {
          id: 8,
          question: "dataProtectionQuestion1",
          answer: "dataProtectionAnswer1",
        },
        {
          id: 8,
          question: "dataProtectionQuestion2",
          answer: "dataProtectionAnswer2",
        },
        {
          id: 9,
          question: "eVechileChargingQuestion1",
          answer: "eVechileChargingAnswer1",
        }
      ];
  
      dispatch({
        type: "FETCH_FAQ_SUCCESS",
        payload: faqQuestions.filter((questions)=> {
          return questions.id === id;
        }),
        id: id,
      });
    };
  };
  const changeSelectedFaqGroup = (value) => ({
    type: "SELECT_FAQ_GROUP",
    payload: value,
  });


  useEffect(() => {
    // Dispatch action to fetch FAQ group if it doesn't exist
    if (!faqs.data[selectedGroup]) {
      dispatch(fetchFaq(selectedGroup));
    }
    // Re-run the effect when dispatch or faqGroup changes
  }, [dispatch, selectedGroup, faqs.data]);

  
  const getFaqs = (id) => {
    // Dispatch action to fetch FAQs for the selected group
    if (!faqs.data[id]) {
      dispatch(fetchFaq(id));
    }
    // Set the selected group state
    dispatch(changeSelectedFaqGroup(id));
  };

  return (
    <RowContainer className="publicFaq">
      <PageHead>{t("faqTitle")}</PageHead>
      <ColumnContainer>
        <Grouping>
          {faqGroup.data &&
            faqGroup.data.map((item) => {
              return (
                <Group theme={themeColors} className={item.id === selectedGroup && "active"} onClick={() => getFaqs(item.id)} key={item.id}>
                  {t(item.name)}
                </Group>
              );
            })}
        </Grouping>
        <FaqList>
          {faqs.data[selectedGroup] &&
            faqs.data[selectedGroup]
              .sort(() => Math.random() - 0.5)
              .map((item) => {
                return (
                  <FaqItem key={item.question}>
                    <Question>{t(item.question)}</Question>
                    <Answer>{t(item.answer)}</Answer>
                  </FaqItem>
                );
              })}
        </FaqList>
      </ColumnContainer>
    </RowContainer>
  );
};

export default withLayout(Faq);
