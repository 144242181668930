import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoForm from "../../../elements/form";
import { Row, RowContainer } from "../../../styles/containers/styles";
import { PageHead } from "../../../styles/headings";
import Layout from "../../common/layout";
import { Concern, Concerns, Type, CheckBox, Label } from "./styels";
import { useTranslation } from "react-i18next";
import { postData } from "../../../../backend/api";
import { ContactLargeIcon, TickIcon } from "../../../../icons";
import moment from "moment";
import i18n from "i18next"; // i18n library for internationalization

const Contact = (props) => {
  const { t } = useTranslation();
  //to update the page title
  useEffect(() => {
    document.title = `Ticket Portal - innopark24`;
  }, [t]);
  //dispatch is using to update the state in store
  const dispatch = useDispatch();

  const themeColors = useSelector((state) => state.themeColors);
  const concerns = useSelector((state) => state.contactConcers);
  const selectedConcern = useSelector((state) => state.selectedConcern);
  const [type, setType] = useState(null);
  const [isAccepted, setIsAccepted] = useState(false);
  const [hover, setHover]= useState(null)
  // const [initialFormInout, setInitialFormInout] = useState([]);
  // const [formInput, setFormInput] = useState(initialFormInout);
  const submitChange = async (post, fields) => {
    try {
      props.setLoaderBox(true);
      let htmlString = "";
      const { data } = props.vehicle;
      const { parkingProgress } = data;
      i18n.changeLanguage("de");

      console.log(post,"post")
      // Name row
      if (post.title || post.firstName || post.lastName) {
        htmlString += `<tr><th>${t("name")}</th><td>${post.title ? post.title : ""}. ${post.firstName ? post.firstName : ""} ${post.lastName ? post.lastName : ""}</td></tr>`;
      }

      // File number row
      if (parkingProgress.fileNumber) {
        htmlString += `<tr><th>${t("fileNumber")}</th><td>${parkingProgress.fileNumber}</td></tr>`;
      }

      // License plate row
      if (parkingProgress.licensePlate) {
        htmlString += `<tr><th>${t("licensePlate")}</th><td>${parkingProgress.licensePlate}</td></tr>`;
      }

      // Post rows
      fields.forEach((item) => {
        if (["licensePlate", "fileNumber", "title", "firstName", "lastName"].includes(item.name) || (typeof post[item.name] === "object" && item.type !== "multiple") || post[item.name].length === 0) {
          return;
        }

        if (item.type === "multiple") {
          htmlString += `<tr><th colspan="2">${t(item.name)}</th></tr>`;
          item.forms.forEach((child, index) => {
            htmlString += `<tr><td colspan="2"><table class="child">`;
            child.forEach((childItem) => {
              htmlString += `<tr><th>${t(childItem.name)}</th><td>${post[item.name][index][childItem.name]}</td></tr>`;
            });
            htmlString += "</table></td></tr>";
          });
        }
        else if (item.type === "date") {
          htmlString += `<tr><th>${t(item.name)}</th><td>${moment(post[item.name]).format("DD.MM.YYYY")}</td></tr>`;
        }
         else {
          htmlString += `<tr><th>${t(item.name)}</th><td>${post[item.name]}</td></tr>`;
        }
      });

      // Final rows
      htmlString += `<tr><th>${t("parkingDate")}</th><td>${parkingProgress.dateOfParkingProcess}</td></tr>`;
      htmlString += `<tr><th>${t("parkingDuration")}</th><td>${parkingProgress.parkingDuration}</td></tr>`;

      // Insert HTML into the page
      i18n.changeLanguage(localStorage.getItem("_lang") !== null ? localStorage.getItem("_lang") : "de");
      console.log(htmlString);

      const finalData = {
        ...post,
        mail: htmlString,
        formHead: t(type.formHead),
        subject: ` ${parkingProgress.fileNumber} / ${parkingProgress.licensePlate}  / ${t(type.title)} / ${t(selectedConcern.concern)}`,
      };
      // console.log(finalData,"final data",type)
      // await SendEmail(post);
      await postData(finalData, "contact/submit")
        .then((response) => {
          if (response.status === 200) {
            props.setMessage({ type: 1, content: t(response.data.message), proceed: "Thank you!" });
            dispatch(changeSelectedConcern(null));
            setType(null);
            setIsAccepted(false);

            // setFormInput(initialFormInout);
          } else {
            props.setMessage({ type: 1, content: "Somethig went wrong", proceed: "Try Again" });
          }
          props.setLoaderBox(false);
        })
        .catch((error) => {
          props.setMessage({ type: 1, content: "An error occurred on the server. Please try again later or contact support if the issue persists.", proceed: "Try Again" });
          props.setLoaderBox(false);
        });
    } catch (error) {
      props.setMessage({ type: 1, content: "An error occurred on the server. Please try again later or contact support if the issue persists.", proceed: "Try Again" });
      props.setLoaderBox(false);
    }
  };
  const setIsAcceptedHandler = (value) => {
    setIsAccepted(value);
  };
  useEffect(() => {
    // Dispatch action to fetch FAQ group if it doesn't exist
    if (!concerns.data) {
      // Dispatch action to fetch FAQs for the first group in the list
      dispatch(fetchContactConcerns());
    }
    // Re-run the effect when dispatch or faqGroup changes
  }, [dispatch, concerns]);
  useEffect(() => {
    // props.setLoaderBox(false);

    if (selectedConcern && selectedConcern.reason?.length === 1 ? true : false) {
      setType(selectedConcern.reason[0]);
    }
  }, [selectedConcern]);

  const fetchContactConcerns = () => {
    return (dispatch) => {
      const form1 = [
        { type: "text", placeholder: "title", name: "title", validation: "text", default: "", label: "title", minimum: 2, maximum: 10, required: true },
        { type: "text", placeholder: "firstName", name: "firstName", validation: "text", default: "", label: "firstName", minimum: 2, maximum: 50, required: true },
        { type: "text", placeholder: "lastName", name: "lastName", validation: "text", default: "", label: "lastName", minimum: 2, maximum: 50, required: true },
      ];
      const form2 = [
        { type: "text", placeholder: "street", name: "street", validation: "text", default: "", label: "street", minimum: 2, maximum: 20, required: true },
        { type: "text", placeholder: "houseNumber", name: "houseNumber", validation: "text", default: "", label: "houseNumber", minimum: 2, maximum: 15, required: true },
        { type: "text", placeholder: "postalCode", name: "postalCode", validation: "number", default: "", label: "postalCode", minimum: 5, maximum: 10, required: true },
        { type: "text", placeholder: "city", name: "city", validation: "text", default: "", label: "city", minimum: 2, maximum: 20, required: true },
      ];
      const form3 = [
        { type: "text", placeholder: "country", name: "country", validation: "text", default: "", label: "country", minimum: 2, maximum: 20, required: true },
        { type: "text", placeholder: "email", name: "email", validation: "email", default: "", label: "email", minimum: 0, maximum: 120, required: true },
        { type: "text", placeholder: "phoneNumber", name: "phoneNumber", validation: "number", default: "", label: "phoneNumber", minimum: 8, maximum: 12, required: false },
      ];
      const contactFormFull = [...form1, ...form2, ...form3];
      const contactForm = [
        ...form1,
        ...form3,
        { type: "textarea", placeholder: "description", name: "description", validation: "text", default: "", label: "description", minimum: 10, maximum: 500, required: true },
      ];
    
      const concerns = [
        {
          id: 1,
          concern: "concern1",
          img:"/icons/Autofahrer.svg",
          img2:"/icons/Autofahrer_White.svg",
          reason: [
            {
              title: "concern1title1",
              acceptRequired: true,
              description: "concern1description1",
              formHead: "concern1title1",
              form: [...contactFormFull, { type: "file", placeholder: "document", name: "document", validation: "text", default: "", label: "document", minimum: 2, maximum: 20, required: false }],
            },
            {
              title: "concern1title2",
              acceptRequired: true,
              description:
                "concern1description2",
              formHead: "concern1title2",
              form: [...contactFormFull, { type: "file", placeholder: "document", name: "document", validation: "text", default: "", label: "document", minimum: 2, maximum: 20, required: true }],
            },
          ],
        },
        {
          id: 2,
          concern: "concern2",
          img:"/icons/Rechnung.svg",
          img2:"/icons/Rechnung_White.svg",
          reason: [
            {
              title: "concern2title1",
              acceptRequired: true,
              description:
                "concern2description1",
              formHead: "concern2title1",
              form: [...contactForm.map((item) =>
                (item.name === "email") ? { ...item, required: true } : (item.name==="description" || item.name==="title") ?{ ...item, required: false } : item
              ), { type: "file", placeholder: "proofDocument", name: "document", validation: "text", default: "", label: "proofDocument", minimum: 2, maximum: 20, required: true }],
          
            },
            {
              title: "concern2title2",
              acceptRequired: false,
              description:
                "concern2description2",
              formHead: "concern2title2",
              form: [
                {
                  type: "multiple",
                  placeholder: "paymentRequests",
                  name: "paymentRequests",
                  validation: "array",
                  forms: [
                    [
                      { type: "text", placeholder: "fileNumber", name: "fileNumber", validation: "fileNumber", default: "", label: "fileNumber", minimum: 5, maximum: 11, required: true,capiatlize:true },
                      { type: "text", placeholder: "licensePlate", name: "licensePlate", validation: "licensePlate", default: "", label: "licensePlate", minimum: 5, maximum: 11, required: true,capiatlize:true },
                    ],
                  ],
                  default: "",
                  label: "paymentRequests",
                  minimum: 1,
                  maximum: 5,
                  required: false,
                },
                { type: "text", placeholder: "installmentAmount", name: "installmentAmount", validation: "amount", default: "", label: "installmentAmount", minimum: 2, maximum: 10, required: true },
                { type: "date", placeholder: "startDate", name: "startDate", validation: "text", default: "", label: "startDate", minimum: 2, maximum: 30, required: true },
              ],
            },
          ],
        },
        {
          id: 3,
          concern: "concern3",
          img:"/icons/Behinderung.svg",
          img2:"/icons/Behinderung_White.svg",
          reason: [
            {
              title: "concern3title1",
              acceptRequired: true,
              description:
                "concern3description1",
              formHead: "concern3title1",
              form: [...contactForm.map((item) =>
                (item.name === "email") ? { ...item, required: true } : item.name==="description" ?{ ...item, required: false } : item
              ), { type: "file", placeholder: "proofDocument", name: "document", validation: "text", default: "", label: "proofDocument", minimum: 2, maximum: 20, required: true }],
            },
          ],
        },
        {
          id: 4,
          concern: "concern4",
          img:"/icons/Auto defekt.svg",
          img2:"/icons/Auto defekt_White.svg",
          reason: [
            {
              title: "concern4title1",
              acceptRequired: true,
              description:
                "concern4description1",
              formHead: "concern4title1",
              form: [{ type: "file", placeholder: "proofDocument", name: "document", validation: "text", default: "", label: "proofDocument", minimum: 2, maximum: 20, required: true }],
            },
          ],
        },
        {
          id: 5,
          concern: "concern5",
          img:"/icons/E Auto.svg",
          img2:"/icons/E Auto_White.svg",
          reason: [
            {
              title: "concern5title1",
              acceptRequired: true,
              description:
                "concern5description1",
              formHead: "concern5title1",
              form: [{ type: "file", placeholder: "proofDocument", name: "document", validation: "text", default: "", label: "proofDocument", minimum: 2, maximum: 20, required: true }],
            },
          ],
        },
        {
          id: 6,
          concern: "concern6",
          img:"/icons/Datenschutz.svg",
          img2:"/icons/Datenschutz_White.svg",
          reason: [
            {
              title: "concern6title1",
              acceptRequired: true,
              description:
                "concern6description1",
              formHead: "concern6title1",
              form: [...contactForm.map((item) =>
                (item.name === "email" || item.name==="phoneNumber") ? { ...item, required: true } : item.name==="description" ?{ ...item, required: false } : item
              ), { type: "file", placeholder: "proofDocument", name: "document", validation: "text", default: "", label: "proofDocument", minimum: 2, maximum: 20, required: false }],
            }
          ],
        },
        {
          id: 7,
          concern: "concern7",
          img:"/icons/Mitarbeiter (1).svg",
          img2:"/icons/Mitarbeiter_White.svg",
          reason: [
            {
              title: "concern7title1",
              description: "concern7description1",
            },
          ],
        },
        {
          id: 8,
          concern: "concern8",
          img:"/icons/Anderes.svg",
          img2:"/icons/Anderes_White.svg",
          reason: [
            {
              title: "concern8title1",
              acceptRequired: true,
              description: "concern8description1",
              formHead: "concern8title1",
              form: contactForm.map((item) =>
                (item.name === "email" || item.name==="phoneNumber") ? { ...item, required: true } : item
              ),
            },
          ],
          
        },
      ];
      dispatch({
        type: "FETCH_CONTACT_CONCERNS",
        payload: concerns,
      });
    };
  };
  const changeSelectedConcern = (value) => {
    return (dispatch) => {
        dispatch({ type: "SELECTED_CONCERN", payload: value });
    };
  };

console.log(concerns.data,"concern")
  return (
    <RowContainer>
      <PageHead>{t("contactTitle")}</PageHead>
      <Concerns className={selectedConcern && "shrink"}>
        {concerns.data &&
          concerns.data.map((item) => {
            const isActive = selectedConcern?.id === item.id;
            const isHovered = hover === item.id;
            return (
              <Concern
                key={item.id}
                theme={themeColors}
                className={isActive ? "active" : ""}
                onClick={() => {
                  dispatch(changeSelectedConcern(item));
                  setType(null);
                  setIsAccepted(false);
                }}
                onMouseEnter={()=>setHover(item.id)}
                onMouseLeave={()=>setHover(null)}
              >
                {/* <ContactLargeIcon /> */}
                {(isActive || isHovered) ? 
                <img src={item?.img2} alt="" style={{width:"70px",height:"70px"}} /> 
                :
                <img src={item?.img} alt="" style={{width:"70px",height:"70px"}} /> 
                }
                <span>{t(item.concern)}</span>
              </Concern>
            );
          })}
      </Concerns>
      {selectedConcern && (
        <Row className={selectedConcern.reason?.length === 1 ? "hide" : ""}>
          <p style={{fontSize:"18px",fontWeight:600,}}>{t("selectReason")}</p>
          <Concerns style={{justifyContent:'start'}} className={type && "shrink" && "center"}>
            {selectedConcern.reason &&
              selectedConcern.reason.map((item) => {
                return (
                  <Type
                    key={item.title}
                    theme={themeColors}
                    className={type === item ? "active shrink" : "shrink"}
                    onClick={() => {
                      setType(item);
                      setIsAccepted(false);
                    }}
                  >
                    {/* <TickIcon></TickIcon> */}
                    <span>{t(item.title)}</span>
                  </Type>
                );
              })}
          </Concerns>
        </Row>
      )}
      {type && selectedConcern && (
        <Row>
          <p style={{fontSize:18,fontWeight:600}}>{t(selectedConcern.concern) + ` ` + t(type.title)}</p>

          <p dangerouslySetInnerHTML={{ __html: t(type.description) }}></p>
          {type.form && (
            <Label className="sticky">
              <CheckBox
                checked={isAccepted}
                type="checkbox"
                onChange={() => {
                  setIsAccepted((prev) => !prev);
                }}
              />
              {t("agreement")}
            </Label>
          )}
        </Row>
      )}

      {type && isAccepted
        ? type.form && (
            <Row className={!isAccepted && "hide"}>
              <AutoForm
                useCaptcha={false}
                useCheckbox={type.acceptRequired}
                description={t(type.description)}
                key={type.description}
                formType={"post"}
                header={t(type.formHead)}
                formInput={type.form}
                submitHandler={submitChange}
                button={t("submit")}
                isOpenHandler={setIsAcceptedHandler}
                isOpen={true}
                plainForm={false}
                page={"contact"}
              ></AutoForm>
            </Row>
          )
        : null}
    </RowContainer>
  );
};

export default Layout(Contact);
