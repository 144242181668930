import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 50px);
`;

export const SideBar = styled.div`
  display: flex;
  flex: 1 1 12em;
  flex-direction: column;
  background-color: #ffffff;
  color: white;
  @media screen and (max-width: 768px) {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    visibility: collapse;
    // min-height: 60%;
    // max-height: 80%;
    height: calc(100vh - 50px);
    z-index: 1;
    &.active {
      visibility: visible;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1 1 calc(100% - 15em);
  border-radius:20px 0 0 0 ;
  padding: 1em;
  overflow: auto;
  box-shadow: inset rgb(0 0 0 / 6%) 0px 0px 8px 7px;
  background: #F0F5FF;
  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
    display: block;
  }
`;
