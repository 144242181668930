import styled from "styled-components";
export const Header = styled.div`
  display: flex;
  padding: 10px;
  &.hd {
    justify-content: space-between;
    align-items: center;
    color: #000;
  }
  @media (min-width: 768px) {
    &.hd {
      display: none;
    }
  }
`;
export const Nav = styled.nav`
  padding: 1em;
  display: flex;
  gap:4px;
  flex-direction: column;
  color: ${(props) => props.theme.secForeground};
  a {
  border-radius:10px;
    text-decoration: none;
    font-weight:700;
    color: ${(props) => props.theme.secForeground};
    padding-left: 2em;
    color: #08225f;
    text-transform: uppercase;
    height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 17px;
  }
  a.active,
  a:hover {
    color:white;
    background: #19A0F9;
    box-shadow: rgb(0 0 0 / 16%) -1px 0px 4px;
  }
  a.active::after {
    content: "";
    width: 6px;
    color: white;
    height: 100%;
    margin-left: auto;
  }
  a svg {
    margin-right: 10px;
  }
`;
// #08225f