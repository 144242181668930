import styled from "styled-components";

export const ListContainer = styled.div`
  display: flex;
`;
export const ListItemHead = styled.div`
  font-weight: bold;
  font-size: 18px;
  display: flex;
  margin: 0;
  color: #001E5E;
  padding: 0.5em 1em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid white;
  // text-transform: uppercase;
  &:first-child {
    margin-top: 0;
  }
`;
export const ListItem = styled.div`
  display: flex;
  gap: 10px;
  padding: 0.5em 1em;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    // flex-direction:column;
  };
  svg {
    margin-top: 3px;
    margin-right: 10px;
  }
`;
export const ListItemBold = styled.div`
  display: flex;
  gap: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #001E5E;
  padding: 0.5em 1em;
  svg {
    margin-right: 10px;
  }
`;

export const ListItemQuarter = styled.div`
  display: flex;
  flex: 1 1 25%;
  &.head{
    font-size: 18px;
    font-weight: 700;
    color: #001E5E;
  }
  span {
    margin-left: 10px;
    cursor: pointer;
  }
  &.paid
  {
    color:green;
  }
  &.canceled
  {
    color:red;
  }
  &.open
  {
    color:green;
  }
  @media screen and (max-width: 768px) {
  // flex: 0.5 1 auto;
  width:30%;
    &.hm {
      display: none;
    }
  }
`;
export const ToolTipContainer = styled.span`
  position: relative;
  display:flex;
  align-items:center;
`;

export const ToolTip = styled.div`
  position: absolute;
  top: 0;
  left: 19px;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  font-size: 18px;
  color: #333;
  white-space: nowrap;
  margin-bottom: 10px;
  cursor: initial;
  &.language {
    top: 27px;
    left: -40px;
    font-size: 18px;
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
`;
