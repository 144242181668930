import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Header, Nav } from "./styels";
import { useTranslation } from "react-i18next"; // react-i18next hook for translations
import { useDispatch, useSelector } from "react-redux";
import { menuStatus } from "../../../../../store/actions/common";
import { ContactIcon, FaqIcon, HomeIcon, LogoutIcon } from "../../../../../icons";
import LanguageTooltip from "../../../../elements/tooltip";
import { Logout, User } from "../header/styels";
import { clearVehicle } from "../../../../../store/actions/vehicle";
const Menu = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const themeColors = useSelector((state) => state.themeColors);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Header className="hd">
        <User>{props.vehicle.fileNumber + ` / ` + props.vehicle.licensePlate}</User>
        <Logout
          onClick={() => {
            dispatch(clearVehicle());
            navigate("/");
          }}
        >
          <LogoutIcon />
        </Logout>
        <LanguageTooltip></LanguageTooltip>
      </Header>
      <Nav theme={themeColors}>
        {/* Link to the home page */}
        <Link
          onClick={() => {
            dispatch(menuStatus());
          }}
          className={location.pathname === "/start" ? "active" : ""}
          to="/start"
        >
          {/* <HomeIcon />  */}
          {t("start")}
        </Link>
        {/* Link to the privacy page */}
        <Link
          onClick={() => {
            dispatch(menuStatus());
          }}
          className={location.pathname === "/faq" ? "active" : ""}
          to="/faq"
        >
          {/* <FaqIcon /> */}
           {t("faq")}
        </Link>
        {/* Link to the imprint page */}
        <Link
          onClick={() => {
            dispatch(menuStatus());
          }}
          className={location.pathname === "/contact" ? "active" : ""}
          to="/contact"
        >
          {/* <ContactIcon />  */}
          {t("contact")}
        </Link>
      </Nav>
    </>
  );
};

export default Menu;
