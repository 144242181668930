import React from "react";
import styled from "styled-components";

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 450px;
  border: 0;
`;

const LocationLabel = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-weight: bold;
`;

const GoogleMap = () => {
  return (
    <MapContainer>
      {/* <LocationLabel>
        📍 EDEKA, Leutstettenerstr.17, 82319 Starnberg
      </LocationLabel> */}
      <StyledIframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2669.417447097759!2d11.346571676289622!3d48.005644371229174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479dd02f9446af9d%3A0x7007b0990ab2c48e!2sEDEKA!5e0!3m2!1sen!2sin!4v1738916021475!5m2!1sen!2sin"
        loading="lazy"
      ></StyledIframe>
    </MapContainer>
  );
};

export default GoogleMap;
