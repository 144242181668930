import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ColumnContainer, RowContainer } from "../../../styles/containers/styles";
import { ListItem, ListItemBold, ListItemHead, ListItemQuarter, ToolTip, ToolTipContainer } from "../../../styles/list/styles";
import Layout from "../../common/layout";
import { Section, Tab, TabContainer, TabHeader, TabLink } from "./styels";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CarIcon, DateIcon, DurationIcon, FileIcon, LocationIcon, QuestionIcon } from "../../../../icons";
import GoogleMap from "./googleMap";
const Start = (props) => {
  const { t } = useTranslation();
  //to update the page title
  useEffect(() => {
    document.title = `Ticket Portal - innopark24`;
  }, [t]);
  // Use the useTranslation hook from react-i18next to handle translations

  const [showTooltip, setShowTooltip] = useState(false);
  const [activeTab, setActiveTab] = useState("parking-process");
  const themeColors = useSelector((state) => state.themeColors);

  // const currentDate = new Date();
  // const parkingDateObject = new Date(props.vehicle.data.parkingProgress.dateOfParkingProcess);
  // const endDate = new Date(parkingDateObject.getTime() + (currentDate.getTime() - parkingDateObject.getTime()));
  // const duration = currentDate.getTime() - parkingDateObject.getTime();
  // const totalDuration = duration / 60000;
  // const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  // const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  // const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

  // const [lat] = useState(props.vehicle.data.parkingProgress.map.latitude);
  // const [lng] = useState(props.vehicle.data.parkingProgress.map.longitude);
  // const mapRef = useRef(null);
  // useEffect(() => {
  //   //setting google map latitute and longitude
  //   const map = new window.google.maps.Map(mapRef.current, {
  //     center: { lat, lng },
  //     zoom: 1,
  //   });
  //   //adding that in to maps in the window
  //   new window.google.maps.Marker({ position: { lat, lng }, map });
  // }, [lat, lng]);
  const currency = "EUR";
  const { parkingProgress, paymentDetails } = props.vehicle.data;
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  // console.log(paymentDetails,"details")
  return (
    <TabContainer>
      <TabHeader>
        <TabLink theme={themeColors} className={activeTab === "parking-process" && "active"} onClick={() => setActiveTab("parking-process")}>
          {t("parkingProcess")}
        </TabLink>
        <TabLink theme={themeColors} className={activeTab === "parking-rules" && "active"} onClick={() => setActiveTab("parking-rules")}>
          {t("parkingRules")}
        </TabLink>
      </TabHeader>
      {/* tab for parking process */}
      <Tab active={activeTab === "parking-process"}>
        <RowContainer>
          <ColumnContainer>
            <Section>
              <ListItemHead> {t("yourParkingProcess")}</ListItemHead>
              <ListItem>
                <DateIcon /> {moment(parkingProgress.dateOfParkingProcess).format("DD.MM.YYYY")}
              </ListItem>
              <ListItem>
                <FileIcon /> {parkingProgress.fileNumber}
              </ListItem>
              <ListItem>
                <CarIcon /> {parkingProgress.licensePlate}
              </ListItem>
              <ListItem>
                <DurationIcon /> {moment(parkingProgress.parkingStartDate).format("h:mm") + ` - ` + moment(parkingProgress.parkingEndDate).format("h:mm")}
              </ListItem>
            </Section>
            <Section>
              <ListItemHead> {t("yourParkingTime")}</ListItemHead>
              <ListItem>
                {t("yourParkingTime")} : {parkingProgress.parkingDuration} {t("minutes")}
              </ListItem>
              <ListItem>
                {t("allowedParkingTime")} : {parkingProgress.allowedParkingDuration} {t("minutes")}
              </ListItem>
            </Section>
          </ColumnContainer>
          <Section>
            <ListItemBold>
              <ListItemQuarter className="head">{t("dueAmount")}</ListItemQuarter>
              <ListItemQuarter className="head">{t("paymentDate")}</ListItemQuarter>
              <ListItemQuarter className="head">{t("status")}</ListItemQuarter>
            </ListItemBold>
            <ListItem>
              <ListItemQuarter style={{fontSize:'18px'}}>
                {paymentDetails.dueAmount.toFixed(2) + ` ` + currency}
                <ToolTipContainer onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                  <QuestionIcon />
                  {showTooltip && (
                    <ToolTip>
                      {paymentDetails.contractualPenalty.toFixed(2) >= 0.01 &&
                      <ListItemQuarter>{t("contractualPenalty") + ` : ` + paymentDetails.contractualPenalty.toFixed(2) + ` ` + currency}</ListItemQuarter>
                      }
                      {paymentDetails.handlingFee.toFixed(2) >= 0.01 &&
                      <ListItemQuarter>{t("handlingFee") + ` : ` + paymentDetails.handlingFee.toFixed(2) + ` ` + currency}</ListItemQuarter>
                      }
                      {paymentDetails.registrationFee.toFixed(2) >= 0.01 &&
                      <ListItemQuarter>{t("registrationFee") + ` : ` + paymentDetails.registrationFee.toFixed(2) + ` ` + currency}</ListItemQuarter>
                      }
                      { paymentDetails.reminderfee1.toFixed(2) >= 0.01 &&
                      <ListItemQuarter>{t("reminderFee1") + ` : ` + paymentDetails.reminderfee1.toFixed(2) + ` ` + currency}</ListItemQuarter>
                      }
                      {paymentDetails.reminderfee2.toFixed(2) >= 0.01 &&
                      <ListItemQuarter>{t("reminderFee2") + ` : ` + paymentDetails.reminderfee2.toFixed(2) + ` ` + currency}</ListItemQuarter>
                      }
                      { paymentDetails.totalAmountPaid.toFixed(2) >= 0.01 &&
                        <ListItemQuarter>{t("totalAmountPaid") + ` : ` + paymentDetails.totalAmountPaid.toFixed(2) + ` ` + currency}</ListItemQuarter>
                      }
                    </ToolTip>
                  )}
                </ToolTipContainer>
              </ListItemQuarter>
              <ListItemQuarter style={{fontSize:'18px'}}>{parkingProgress.paymentDueDate ? moment(parkingProgress?.paymentDueDate).format("DD.MM.YYYY") : ""}</ListItemQuarter>
              <ListItemQuarter className={paymentDetails.paymentStatus}>{`${t(paymentDetails.paymentStatus)}`}</ListItemQuarter>
            </ListItem>
          </Section>
        </RowContainer>
      </Tab>
      {/* tab for parking rules */}
      <Tab active={activeTab === "parking-rules"}>
        <RowContainer>
          <ColumnContainer>
            <Section>
              <ListItemBold>{t("parkingSpot")}</ListItemBold>
              <ListItem>
                <LocationIcon /> {parkingProgress.parkingLotAddress}
              </ListItem>
              <ListItemBold>{t("scope")}</ListItemBold>
              <ListItem>
                <DateIcon /> {parkingProgress.hoursOfValidity}
              </ListItem>
              <ListItemBold>{t("allowedParkingTime")}</ListItemBold>
              <ListItem>
                <DurationIcon /> {parkingProgress.allowedParkingDuration} {t("minutes")}
              </ListItem>
            </Section>
            <Section>
              {/* <iframe
                title="new"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3936.9914873728962!2d-79.81120147224523!3d9.334019665323561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fab6bbfd2feba61%3A0xf7b5d52e9ce2a997!2sManzana%20030111%20107-164%2C%20Sabanitas%2C%20Panama!5e0!3m2!1sen!2sin!4v1675937425253!5m2!1sen!2sin"
                height="450"
                style={{ border: 0 }}
                loading="lazy"
              ></iframe> */}
              <GoogleMap/>
            </Section>
          </ColumnContainer>
        </RowContainer>
      </Tab>
    </TabContainer>
  );
};
// exporting the page with parent container layout..
export default Layout(Start);
