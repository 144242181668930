import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { postData } from "../../../backend/api";
import AutoForm from "../../elements/form";
import Footer from "../footer";
import { FormContainer, ImageContainer, MainContainer } from "./styles";
import banner from "../../../images/public/banner.png";
//using translation
import { useTranslation } from "react-i18next";
import { clearVehicle, fetchVehicle } from "../../../store/actions/vehicle";
import { useDispatch, useSelector } from "react-redux";
import withLayout from "../layout";
import { ColumnContainer, InnerContainer, RowContainer } from "../../styles/containers/styles";
import Header from "../header";

const Login = (props) => {
  useEffect(() => {
    document.title = `Ticket Portal - innopark24`;
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);
  const { t } = useTranslation();
  const formInput = [
    {
      type: "text",
      placeholder: "000-000-000-000-000-000",
      name: "fileNo",
      validation: "fileNumber",
      default: "",
      label: "fileNumber",
      minimum: 5,
      maximum: 23,
      required: true,
    },
    {
      type: "text",
      placeholder: "XXX",
      name: "licenseCode",
      validation: "licenseCode",
      default: "",
      label: "",
      minimum: 3,
      maximum: 3,
      required: true,
      beforeText:"D"
    },
    {
      type: "text",
      placeholder: "XX000",
      name: "licensePlate",
      validation: "licensePlate",
      default: "",
      label: "",
      minimum: 5,
      maximum: 9,
      required: true,
    },
    {
      type: "text",
      placeholder: "foreignPlate",
      name: "foreignPlate",
      validation: "foreignPlate",
      default: "DAHIP12345",
      label: "foreignPlate",
      minimum: 5,
      maximum: 12,
      required: true,
    }
  ];
  const jsonData = {
    "parkingProgress": {
      "fileNumber": "K11-120-210-630-123-124",
      "licensePlate": "STA-EF233",
      "dateOfParkingProcess": "2021-06-30",
      "parkingStartDate": "2021-06-30 12:31:00",
      "parkingEndDate": "2021-06-30 15:55:00",
      "parkingDuration": 204,
      "allowedDuration": 100,
      "parkingLotAddress": "EDEKA, Leutstettenerstr.17, 82319 Starnberg",
      "hoursOfValidity": "",
      "allowedParkingDuration": 100
    },
    "paymentDetails": {
      "paymentStatus": "open",
      "paymentDate": "2022-06-30",
      "dueAmount": 55.10,
      "contractualPenalty": 30,
      "handlingFee": 7.6,
      "registrationFee": 10,
      "holderRequestFee": 0,
      "reminderfee1": 5,
      "reminderfee2": 2.5,
      "totalAmountPaid": 0.01
    }
  }
  const { setLoaderBox } = props;
  useEffect(() => {
    if (vehicle.data.parkingProgress) {
      navigate("/start");
    }
    setLoaderBox(vehicle.isLoading);
    if (vehicle.error !== null) {
      props.setMessage({ type: 1, content: t(vehicle.error), proceed: "Okay" });
      dispatch(clearVehicle());
    }
  }, [vehicle, navigate, setLoaderBox, t, props, dispatch]);

  const isCreatingHandler = (value, callback) => {};

  const submitChange = async (post) => {
    // console.log(post,"post")
    dispatch(fetchVehicle(post));
    // dispatch({
    //   type: "FETCH_VEHICLE_SUCCESS",
    //   payload: jsonData,
    // });
    // post.licensePlate = "ABC-DE 123";
  };

  return (
    <MainContainer>
      <Header> </Header>
      <ColumnContainer
        className="login"
      >
        <FormContainer>
          <AutoForm useCaptcha={false} showLicense={true} formType={"post"} description={t("loginDescription")} header={t("loginHead")} formInput={formInput} submitHandler={submitChange} button={t("search")} isOpenHandler={isCreatingHandler} isOpen={true} plainForm={true} page={"login"}></AutoForm>
        </FormContainer>
      </ColumnContainer>
      <RowContainer className="white">
        <InnerContainer>

          <h1 style={{color:"#28235B",fontSize:'1.7em'}}>{t("faqRed")}</h1>
          <h5 style={{color:"#28235B",fontSize:'18px'}}>{t("faqRed2")}</h5>
          <Link style={{float: `left`,backgroundColor:"#28235C",fontSize:'18px', fontWeight:'bold'}} 
          to="/publicfaq">{t("faq")}</Link>

        </InnerContainer>
      </RowContainer>
      <Footer></Footer>
    </MainContainer>
  );
};

export default withLayout(Login);
